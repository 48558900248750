<template>
    <div class="paymentOrder">
        <div class="title">支付订单</div>
        <!-- 顶部操作栏 -->
        <div class="headBox">
            <div class="right">
                <el-input placeholder="输入ID" clearable v-model="id" size="mini" @input="getData">
                    <template slot="prepend">ID:</template>
                </el-input>
                <el-input placeholder="输入姓名" clearable v-model="customerName" size="mini" @input="getData">
                    <template slot="prepend">姓名:</template>
                </el-input>
                <div class="select">
                    <div class="selectTit">签约状态:</div>
                    <el-select v-model="signingStatus" clearable placeholder="请选择" size="mini" @change="getData">
                        <el-option
                            v-for="item in signingStatusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="select">
                    <div class="selectTit">签约类型:</div>
                    <el-select v-model="signingType" clearable placeholder="请选择" size="mini" @change="getData">
                        <el-option
                            v-for="item in signingTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <!-- 表格栏 -->
        <table-list
            :ButtonWidth="240"
            :tableData="tableData"
            :colrs="colrs"
            :total="total"
            :pagesize="pagesize"
            :pageIndex="pageIndex"
            :selection="$route.query.recovery == 0"
            @currentChange="currentChange"
            @handleSizeChange="handleSizeChange"
        >
            <template #TableItem="scope">
                <span v-if="scope.colr.prop == 'signingType'">
                    {{ scope.row[scope.colr.prop] == 0 ? "单笔" : "分期" }}
                </span>
                <span v-else-if="scope.colr.prop == 'signingStatus'">
                    <span v-if="scope.row[scope.colr.prop] == 1" style="color: #67c23a">
                        {{ scope.row["signingStatusText"] }}
                    </span>
                    <span v-else-if="scope.row[scope.colr.prop] == 2" style="color: #e6a23c">
                        {{ scope.row["signingStatusText"] }}
                    </span>
                    <span v-else-if="scope.row[scope.colr.prop] == 3" style="color: #e44c4c">
                        {{ scope.row["signingStatusText"] }}
                    </span>
                    <span v-else>{{ scope.row["signingStatusText"] }}</span>
                </span>
                <span v-else-if="scope.colr.prop == 'deductedAmount'">
                    <span>{{
                        scope.row["signingAmount"] - scope.row["remainingDeductionAmount"] > 0
                            ? (scope.row["signingAmount"] - scope.row["remainingDeductionAmount"]).toFixed(2)
                            : 0
                    }}</span>
                </span>
                <span v-else-if="scope.colr.prop == 'remainingDeductionAmount'">
                    {{ scope.row[scope.colr.prop] > 0 ? scope.row[scope.colr.prop].toFixed(2) : 0 }}
                </span>
                <span v-else-if="scope.colr.prop == 'contractOrderId'">
                    <span style="color: #409eff; cursor: pointer" @click="check(scope.row)">{{
                        scope.row[scope.colr.prop]
                    }}</span>
                </span>
                <span v-else-if="scope.colr.prop == 'executedNumber'">
                    {{ scope.row[scope.colr.prop] }}
                </span>
                
            </template>
            <template #Button="scope">
                <el-button size="mini" type="text" @click="check(scope.row)">查看</el-button>
                <el-button size="mini" type="text" @click="openListNews(scope.row)">扣款详情</el-button>

                <el-button size="mini" type="text" style="color: #67c23a; margin: 0 10px" @click="repayment(scope.row)"
                    >提前还款</el-button
                >

                <el-button size="mini" type="text" style="color: #e44c4c" @click="relieve(scope.row)">解约</el-button>
            </template>
        </table-list>
        <el-dialog
            :title="title"
            class="yzmDialog"
            :visible.sync="yzmdialogVisible"
            width="25%"
            top="25vh"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <div class="yzmBox">
                <el-form :model="yzmForm" :rules="yzmRules" ref="yzmForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="还款类型：" prop="radio" v-if="isRepayment">
                        <el-radio-group v-model="yzmForm.radio">
                            <el-radio label="线上划扣"></el-radio>
                            <el-radio label="线下完成"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <div class="tit">请输入手机验证码进行验证</div>
                    <el-form-item label="手机号：">
                        <span>+86{{ mobileNumber }}</span>
                    </el-form-item>
                    <el-form-item label="验证码：" prop="yzm">
                        <el-input
                            v-model="yzmForm.yzm"
                            size="small"
                            placeholder="输入验证码"
                            style="width: 160px"
                        ></el-input>
                        <el-button
                            size="mini"
                            type="primary"
                            :disabled="yzmLoading"
                            @click="sendYzm"
                            :loading="yzmLoading"
                        >
                            <span v-if="yzmLoading">{{ codeTimer }}后重新获取</span>
                            <span v-else>获取验证码</span>
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="yzmdialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="signConfirm('yzmForm')">确 认</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import TableList from "../../components/comm/TableList.vue";
import paymentOrderApi from "../../api/paymentOrderApi";
import checkImg from "../../components/comm/checkImg.vue";
import axios from "axios";
export default {
    name: "paymentOrder",
    components: { TableList, checkImg },
    data() {
        return {
            tableData: [],
            colrs: [
                { label: "订单号", prop: "id", width: 230 },
                { label: "客户姓名", prop: "customerName", width: 100  },
                { label: "金额(元)", prop: "signingAmount", width: 100 },
                { label: "签约状态", prop: "signingStatus", width: 100 },
                { label: "签约类型", prop: "signingType", width: 100 },
                { label: "已扣款金额(元)", prop: "deductedAmount", width: 140  },
                { label: "剩余扣款金额(元)", prop: "remainingDeductionAmount", width: 140 },
                { label: "期数", prop: "stagesNumber", width: 100 },
                { label: "已执行期数", prop: "executedNumber", width: 140  },
                { label: "备注", prop: "remarks" },
                { label: "", prop: "", width: "" }
            ],
            total: 0,
            pagesize: 20,
            pageIndex: 1,

            form: {},
            checkForm: {},
            customerName: "",

            newsItemVisible: false,

            messageTit: "",

            clickInfoList: [],
            timer: "",

            yzmdialogVisible: false,
            yzm: "",
            yzmLoading: false,
            codeTimer: 60,
            signRow: {},
            yzmForm: {},
            yzmRules: {
                yzm: [
                    { required: true, message: "未输入验证码", trigger: "blur" },
                    { validator: this.validateYzm, trigger: "blur" }
                ],
                radio: [{ required: true, message: "未选择还款方式", trigger: "change" }]
            },

            visible: false,
            radio: "",

            isRepayment: false,

            title: "验证身份",

            mobileNumber: "",

            customerName: "",
            id: "",
            signingStatusOptions: [
                {
                    label: "未签约",
                    value: 0
                },
                {
                    label: "已签约",
                    value: 1
                },
                {
                    label: "签约中",
                    value: 2
                },
                {
                    label: "已解约",
                    value: 3
                }
            ],
            signingStatus: "",
            signingTypeOptions: [
                {
                    label: "单笔",
                    value: 0
                },
                {
                    label: "分期",
                    value: 1
                }
            ],
            signingType: ""
        };
    },
    mounted() {
        document.title = "代扣系统-支付订单";
        this.getData();
    },
    watch: {},
    created() {},

    methods: {
        getData() {
            this.$ajax
                .post(
                    "//safepayment.com.cn:18080/payOrders/page?pageSize=" + this.pagesize + "&pageNumber=" + this.pageIndex,
                    {
                        customerName: this.customerName,
                        id: this.id,
                        signingStatus: this.signingStatus,
                        signingType: this.signingType
                    }
                )
                .then(res => {
                    if (res.status == 200) {
                        this.tableData = res.data.records;
                        this.tableData.forEach(item=>{
                            this.$set(item,'executedNumber',item.stagesNumber-item.remainingStages)
                        })
                        this.total = res.data.total;
                    }
                });
        },

        handStatus(status) {
            switch (status) {
                case 0:
                    return "未签约";
                case 1:
                    return "已签约";
                case 2:
                    return "签约中";
                case 3:
                    return "已解约";
            }
        },

        check(row) {
            this.$router.push({ name: "/paymentOrder/orderDetails", query: { id: row.id } });
        },

        relieve(row) {
            if (row.executedNumber>0) {
                this.$message({
                    message: '不符合解约条件（订单执行中），请联系业务主管',
                    type: 'warning'
                });
                return
            }

            this.isRepayment = false;
            this.yzmLoading = false;
            this.yzmForm = {};
            this.title = "验证身份";
            this.mobileNumber =
                this.$utils.storage.get("mobileNumber").slice(0, 3) +
                "****" +
                this.$utils.storage.get("mobileNumber").slice(-4);
            this.$confirm("确认解约?", "", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.yzmdialogVisible = true;
                    this.signRow = row;
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消解约"
                    });
                });
        },
        openListNews(row) {
            this.$router.push({ name: "messagePage", query: { id: row.id } });
        },
        del(row) {
            this.$confirm("当前订单已签约，确定解约并删除?", "", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {})
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
        },
        sendYzm() {
            this.codeTimer = 59;
            this.yzmLoading = true;
            window.clearInterval(timer);
            var timer = window.setInterval(() => {
                this.codeTimer--;
                if (this.codeTimer < 1) {
                    this.yzmLoading = false;
                    window.clearInterval(timer);
                }
            }, 1000);
            axios.post("//safepayment.com.cn:18080/contractOrders/sendVerificationCode/" + this.signRow.id).then(res => {
                console.log(res);
                if (res.data.status == 200) {
                    this.$message({
                        type: "success",
                        message: "发送成功"
                    });
                } else {
                    this.$message({
                        type: "info",
                        message: res.data.message
                    });
                }
            });
        },
        signConfirm(yzmForm) {
            this.$refs[yzmForm].validate(valid => {
                if (valid) {
                    if (!this.isRepayment) {
                        this.$ajax
                            .post(
                                "//safepayment.com.cn:18080/payOrders/settlement/" +
                                    this.signRow.id +
                                    "?code=" +
                                    this.yzmForm.yzm
                            )
                            .then(res => {
                                if (res.status == 200) {
                                    this.$message({
                                        type: "success",
                                        message: "已成功解约，并取消快捷支付"
                                    });
                                    this.getData();
                                    this.yzmdialogVisible = false;
                                } else {
                                    this.$message({
                                        type: "warning",
                                        message: res.message
                                    });
                                }
                            });
                    } else {
                        if (this.yzmForm.radio == "线上划扣") {
                            this.$ajax
                                .post(
                                    "//safepayment.com.cn:18080/contractOrders/earlyRepayment/" +
                                        this.signRow.id +
                                        "/online?code=" +
                                        this.yzmForm.yzm
                                )
                                .then(res => {
                                    if (res.status == 200) {
                                        setTimeout(() => {
                                            this.$message({
                                                type: "success",
                                                message: res.message
                                            });
                                            this.getData();
                                            this.yzmdialogVisible = false;
                                        }, 1000);
                                    } else {
                                        this.$message({
                                            type: "warning",
                                            message: res.message
                                        });
                                    }
                                });
                        } else {
                            this.$ajax
                                .post(
                                    "//safepayment.com.cn:18080/contractOrders/earlyRepayment/" +
                                        this.signRow.id +
                                        "/offline?code=" +
                                        this.yzmForm.yzm
                                )
                                .then(res => {
                                    if (res.status == 200) {
                                        setTimeout(() => {
                                            this.$message({
                                                type: "success",
                                                message: res.message
                                            });
                                            this.getData();
                                            this.yzmdialogVisible = false;
                                        }, 1000);
                                    } else {
                                        this.$message({
                                            type: "warning",
                                            message: res.message
                                        });
                                    }
                                });
                        }
                    }
                } else {
                    return false;
                }
            });
        },
        repayment(row) {
            this.isRepayment = true;
            this.title = "提前还款";
            this.yzmLoading = false;
            this.yzmForm = {};
            this.yzmdialogVisible = true;
            this.signRow = row;
            this.mobileNumber =
                this.$utils.storage.get("mobileNumber").slice(0, 3) +
                "****" +
                this.$utils.storage.get("mobileNumber").slice(-4);
        },
        currentChange(pageIndex) {
            this.pageIndex = pageIndex - 1;
        },
        handleSizeChange(pageSize) {
            this.pageIndex = 1;
            this.pagesize = pageSize;
            this.getData();
        }
    }
};
</script>

<style lang="scss" scoped>
.paymentOrder {
    background: #ffffff;
    padding: 20px;
    border-radius: 4px;
    min-height: 85vh;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    .headBox {
        display: flex;
        justify-content: right;
        align-items: center;
        margin-bottom: 15px;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .right {
            display: flex;
            align-items: center;
            gap: 10px;
            .select {
                display: flex;
                align-items: center;
                .selectTit {
                    background-color: #f5f7fa;
                    color: #909399;
                    vertical-align: middle;
                    display: table-cell;
                    position: relative;
                    border: 1px solid #dcdfe6;
                    border-right: 0;
                    border-radius: 4px 0 0 4px;
                    padding: 0 20px;
                    height: 26px;
                    line-height: 26px;
                    white-space: nowrap;
                }
                .el-select {
                    width: 180px;
                    /deep/.el-input__inner {
                        border-radius: 0 4px 4px 0;
                    }
                }
            }
        }
    }

    .elTag {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 320px;
        .elTagSpan {
            display: flex;
            align-items: center;
            img {
                width: 12px;
                height: 12px;
                margin-right: 10px;
            }
        }
    }

    /deep/.el-table .cell {
        line-height: normal !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    /deep/ .el-button--text {
        height: 16px;
        padding: 0;
    }

    .infoBox {
        .header_tit {
            height: 40px;
            line-height: 44px;
            background: rgb(241, 245, 249);
            font-weight: 700;
            font-size: 16px;
            padding-left: 10px;
            margin-bottom: 20px;
        }
        .infoItem {
            border-bottom: 1px solid #eee;
            padding: 0 10px 4px 10px;
            margin: 0 10px 20px;
            display: flex;
            justify-content: space-between;
            &:last-child {
                border: 0;
            }
            .tit {
                font-weight: 700;
            }
            .zjz {
                margin-left: 50px;
                margin-top: 5px;
                cursor: pointer;
                display: flex;
                gap: 10px;
                .img {
                    width: 120px;
                    height: 120px;
                    border: 1px dashed #d9d9d9;
                    margin-top: 15px;
                    &:first-child {
                        margin-bottom: 15px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .zjzInfo {
            display: block;
            margin: 0 10px 15px;
        }
    }

    .newsItem {
        cursor: pointer;
        .news {
            padding-left: 12px;
            display: flex;
            align-items: center;
            color: #409eff;

            i {
                font-size: 16px;
                margin-right: 5px;
            }
        }
        .unread {
            color: #f78989;
        }
        /deep/.el-divider--horizontal {
            margin: 18px 0;
        }
    }

    .nItem {
        .n {
            color: #85ce61;
        }
        .error {
            color: #f78989;
        }
    }
    .yzmBox {
        .tit {
            font-weight: 600;
            margin: 20px 0 10px 32px;
        }
        .el-input {
            margin-right: 10px;
        }
        .el-form-item {
            margin-bottom: 5px;
        }
    }
}
</style>


